<template>
  <div class="homepage">
    <section class="hero">
      <h1>HKOIL I-OFFICE</h1>
      <p>Hệ thống quản lý văn phòng điện tử thông minh HKOIL</p>
    </section>
    <section class="content">
      <div class="profile">
        <img src="../assets/logo.png" alt="HKOIL I-OFFICE" class="profile-img" />
        <div class="profile-info">
          <h2>HKOIL I-OFFICE</h2>
          <p class="title">Văn phòng thông minh, năng suất vượt trội</p>
          <button @click="toggleContent" class="toggle-btn">{{ isContentVisible ? 'Ẩn Nội Dung' : 'Hiện Nội Dung' }}</button>
          <div v-show="isContentVisible" class="profile-text">
            <p>Với hệ thống báo cáo kinh doanh thông minh BI và phân tích dữ liệu đa chiều, kết hợp cùng công nghệ AI hiện đại, Hoàng Khải có khả năng xử lý dữ liệu thời gian thực, từ đó đưa ra những quyết định kinh doanh sáng suốt. Chúng tôi không chỉ tối ưu hóa lợi ích cho doanh nghiệp mà còn đảm bảo sự thành công bền vững cho tất cả các đối tác và khách hàng của mình.</p>
            <p>Nhưng trên hết, tại Hoàng Khải, con người là trọng tâm của mọi sự đổi mới. Với mục tiêu “Người Hoàng Khải hạnh phúc”, chúng tôi không ngừng lan tỏa nhịp sống chuyển đổi số đến mọi hoạt động nội bộ. Hoàng Khải không chỉ là nơi làm việc mà còn là một gia đình – nơi mọi người được quan tâm, lắng nghe và chăm sóc từ những điều nhỏ nhất.</p>
            <p>Những công cụ quản trị thông minh và trợ lý ảo được tích hợp để nâng cao năng suất lao động, xử lý công việc nhanh chóng và thông suốt. Các hệ thống hiện đại giúp chủ động phát hiện sớm nguy cơ, cảnh báo kịp thời, đồng thời nâng cao khả năng ứng dụng công nghệ vào thực tiễn. Những buổi chia sẻ, hội thảo nội bộ được tổ chức thường xuyên giúp nhân viên không ngừng nâng cao kỹ năng, cập nhật xu hướng mới, và tận dụng tối đa các tiến bộ khoa học kỹ thuật vào công việc.</p>
            <p>Với sự quyết tâm từ ban lãnh đạo và tinh thần đoàn kết của toàn thể nhân viên, chúng tôi tự tin vào tương lai tươi sáng của Hoàng Khải, nơi sự tử tế và chân thành là kim chỉ nam trong mọi hoạt động.</p>
            <p>Hãy cùng Hoàng Khải kiến tạo những giá trị bền vững, vươn đến những đỉnh cao thành công mới!</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      isContentVisible: true
    };
  },
  methods: {
    toggleContent() {
      this.isContentVisible = !this.isContentVisible;
    }
  }
};
</script>

<style scoped>
.homepage {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 10px 5px 5px 10px;
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  max-width: 1200px; /* Đặt kích thước tối đa cho trang */
  margin: 0 auto; /* Trung tâm hóa trang */
}

.hero {
  background: linear-gradient(to right, #00ffe3, #f2a210);
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  margin-bottom: 20px; /* Thêm khoảng cách dưới hero */
}

.hero h1 {
  font-size: 2.5em;
  margin: 0;
  font-weight: 700;
}

.hero p {
  font-size: 1.2em;
  margin: 10px 0;
  font-weight: 300;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.profile-img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.profile-info {
  flex: 1; /* Để nội dung chiếm toàn bộ không gian còn lại */
  text-align: left; /* Căn chỉnh chữ bên trái */
}

.profile-info h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.profile-info .title {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
  font-weight: 400;
}

.profile-info .toggle-btn {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 20px;
  transition: background 0.3s ease;
}

.profile-info .toggle-btn:hover {
  background: #0056b3;
}

.profile-text {
  margin-bottom: 15px;
  font-size: 1em;
  color: #555;
  /* Thêm chiều cao tối thiểu nếu cần */
  min-height: 100px; /* Ví dụ, chiều cao tối thiểu khi ẩn */
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    padding: 30px;
  }

  .hero h1 {
    font-size: 2em;
  }

  .hero p {
    font-size: 1em;
  }

  .profile {
    flex-direction: column; /* Chuyển đổi bố cục thành cột trên màn hình nhỏ */
    align-items: center; /* Căn giữa nội dung */
    padding: 15px;
  }

  .profile-img {
    width: 120px;
    height: 120px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .profile-info {
    text-align: center; /* Căn chỉnh chữ ở giữa */
  }

  .profile-info h2 {
    font-size: 1.5em;
  }

  .profile-info .title {
    font-size: 1em;
  }

  .profile-info .toggle-btn {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 20px;
  }

  .hero h1 {
    font-size: 1.5em;
  }

  .hero p {
    font-size: 0.9em;
  }

  .profile {
    padding: 10px;
  }

  .profile-img {
    width: 100px;
    height: 100px;
  }

  .profile-info h2 {
    font-size: 1.2em;
  }

  .profile-info .title {
    font-size: 0.9em;
  }

  .profile-info .toggle-btn {
    font-size: 0.8em;
  }

  .profile-text {
    font-size: 0.9em;
  }
}
</style>
